import React from 'react'
import styled, { css } from 'styled-components'

import { inputStyle } from '../Input/Input';
import { media } from '../../styles/utils';

class CheckboxField extends React.Component {	

    state = {
        active: false,
    }

    toggle = (state) => {
        const { active } = this.state;
        const { onChangeValue, validator } = this.props;

        this.setState({
            active: !active
        })

        onChangeValue(!active, validator)
    }

	render() {
        const { active } = this.state;
        const { value, onChangeValue, validator, label, theme } = this.props;

        return (
            <Wrapper
                onClick={this.toggle}
            >
                <Checkbox
                    active={active}
                    theme={theme}
                />
                <Label
                    theme={theme}
                    className={'checkbox-label'}
                    dangerouslySetInnerHTML={{__html: label}}
                />
            </Wrapper>
        )
	}
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    border: 0;

    display: flex;
    cursor: pointer;
    user-select: none;

    margin: 26px 0;
`

const Label = styled.div`
    padding-top: 4px;
    &, a, p {
        font-size: 14px;
        line-height: 16px;
        color: black;
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
    }
`

const Checkbox = styled.div`
    width: 16px;
    height: 16px;
    border: solid 1px #979797;
    position: relative;
    margin-right: 10px;
    flex: 0 1 16px;
    transform: translateY(3px);
    border-radius: 4px;
    min-width: 16px;

    ${props => {
        if (props.active && props.theme !== 'white') return css`
            &::before {
                content: '';
                width: 9px;
                height: 9px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #666;
            }
        `
    }}

    ${props => {
        if (props.active && props.theme == 'white') return css`
            &::before {
                content: '✕';
                font-size: 15px;
                width: 16px;
                height: 16px;
                position: absolute;
                color: white;
                transform: translate(1px, -3px)
            }
        `
    }}
    
`

export default CheckboxField

