import React, { useState } from 'react'
import { navigate, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, headingText, button } from '../../styles/global'
import { border } from '../../styles/colors';
import { getGlobalData } from '../../utils';

const CallToAction = (props) => {
    const { global, data } = getGlobalData('call_to_action')

    return (
        <Wrapper>
            <Container>
                <HeadingText>
                    <Heading>{data.title}</Heading>
                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{__html: data.text}}
                        />

                        {data.use_enquire_button && (
                            <InternalLink
                                to={data.enquire_button_link}
                            >
                                {data.enquire_button_text}
                            </InternalLink>
                        )}
                    </Info>
                </HeadingText>
            </Container>
        </Wrapper>

	)
}

// Shared

const Heading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const InternalLink = styled(Link)`
    ${button}   
`


const ExternalLink = styled.a`
    ${button}   
`

const HeadingText = styled.div`
    ${headingText}
    width: 100%;
`

// Layout

const Wrapper = styled.div`
    background: #F1F1F1;
    width: 100%;
    border-top: 1px solid ${border};
    border-bottom: 1px solid ${border};

    display: flex;
    justify-content: center;
`

const Container = styled.div`
	${container}
    ${padding}
    min-height: 298px;
    padding-top: 40px;
    box-sizing: border-box;

    ${Info} {
        align-items: flex-start;
    }

    ${media.phone`
        padding-top: 20px;

        ${HeadingText} {
            margin-bottom: 47px;
        }
    `}
`

export default CallToAction
