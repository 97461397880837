import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useViewportScroll, useTransform } from "framer-motion";
import styled, { css } from 'styled-components'
import { bgImage } from '../../styles/global';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

const ParallaxImage = ({ image, ...style }) => {
    return (
        <BGImage 
            className={'parallax-image'}
            image={image}
        />
    )
}

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
    will-change: transform;
`

export default ParallaxImage