import React, { Component } from 'react'
import { Helmet } from "react-helmet";

const MetaTags = (props) => {

    const { 
        title, 
        description, 
        opengraph_description,
        opengraph_image,
        opengraph_title,
        twitter_description, 
        twitter_image,
        twitter_title,
    } = props;
    
    return (
        <Helmet
            title={title}
            meta={[
                { name: 'description', content: description},
                { property: 'og:image', content: opengraph_image },
                { property: 'og:title', content: opengraph_title ? opengraph_title : title  },
                { property: 'og:description', content: opengraph_description ? opengraph_description : description },
                { property: 'twitter:card', content: 'summary' }, 
                { property: 'twitter:image', content: twitter_image ? twitter_image : opengraph_image }, 
                { property: 'twitter:title', content: twitter_title ? twitter_title : title },
                { property: 'twitter:description', content: twitter_description ? twitter_description : description}
            ]}
        />
    )
}

MetaTags.defaultProps = {
    title: 'Botanic Horticulture',
}

export default MetaTags