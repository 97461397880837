import React, { useState } from 'react'
import { navigate, Link, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Helmet } from "react-helmet";

import { CallToAction } from '../../components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { olive } from '../../styles/colors';
import { getGlobalData, scrollToTop } from '../../utils';

const Footer = (props) => {
    const { global, data } = getGlobalData('footer')
    const [modalActive, setModalActive] = useState(false)

	return (
        <Wrapper>
            <Container>
                <BackToTop
                    onClick={scrollToTop}
                >
                    Back To Top
                </BackToTop>

                {/* About / Social */}

                <AboutSocial>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.description}}
                    />

                    <SocialLinks>
                        {data.display_instagram_link && (
                            <IconLink
                                type={'instagram'}
                                href={global.social_instagram_link}
                                target={'_blank'}
                            />
                        )}

                        {data.display_facebook_link && (
                            <IconLink
                                type={'facebook'}
                                href={global.social_facebook_link}
                                target={'_blank'}
                            />
                        )}                                           
                    </SocialLinks>
                </AboutSocial>

                {/* Address */}
                
                <Contact>
                    <Address
                        dangerouslySetInnerHTML={{__html: data.address}}
                    />

                    

                    <ContactLink
                        href={`tel:${data.phone}`}
                        target={`_blank`}
                        dangerouslySetInnerHTML={{__html: data.phone}}
                    />

                    <ContactLink
                        href={`mailto:${data.email}`}
                        target={`_blank`}
                        dangerouslySetInnerHTML={{__html: data.email}}
                    />

                    <ContactLink
                        as={'div'}
                        onClick={() => {
                            setModalActive(true)
                        }}
                        dangerouslySetInnerHTML={{__html: 'Subscribe'}}
                    />
                                        
                </Contact>

                <Credit>
                    <MadeBy
                        href={'https://morebymore.com/'}
                        target={'_blank'}
                    >
                        Made By More
                    </MadeBy>
                </Credit>

                {modalActive && (
                    <Modal>
                        <Close
                            onClick={() => {
                                setModalActive(false)
                            }}
                        >
                           Close
                        </Close>

                        <div id="fd-form-6423c7fe434a0c7e2d0a65f0"></div>
                        <Helmet>
                            <script type="text/javascript">
                                {`
                                    window.fd('form', {
                                        formId: '6423c7fe434a0c7e2d0a65f0',
                                        containerEl: '#fd-form-6423c7fe434a0c7e2d0a65f0'
                                    });
                                `}
                            </script>
                        </Helmet>
                    </Modal>
                )}
            </Container>
        </Wrapper>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled.div`
    background: ${olive};
    padding: 57px 0 91px;
    min-height: 342px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;

    ${media.tabletPortrait`
        padding-bottom: 164px;
    `}
`

const Container = styled.div`
	${container}
	${padding}
    height: auto;

    > *,
    > * > * {
        color: white;
    }

    ${media.tabletPortrait`
        flex-direction: column;
    `}
`

// Back To Top

const BackToTop = styled.div`
    font-size: 18px;
    text-decoration: underline;
    flex: 0 1 19%;
    cursor: pointer;
`

// About Social

const AboutSocial = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 35%;

    ${Heading} {
        max-width: 256px;
        margin-bottom: auto;
    }

    ${media.tabletPortrait`
        margin-top: 65px;
    `}
`

const IconLink = styled.a`
    ${bgIcon};   

    ${props => {
        if (props.type == 'instagram') return css`
            background-image: url(${require('../../assets/icons/instagram-white.svg')});
            width: 25px;
            height: 25px;
        `

        if (props.type == 'facebook') return css`
            background-image: url(${require('../../assets/icons/facebook-white.svg')});
            width: 25px;
            height: 24px;
        `
    }}

    &:not(:last-child) {
        margin-right: 20px;
    }
`

const SocialLinks = styled.div`
    display: flex;

    ${media.tabletPortrait`
        position: absolute;
        bottom: 64px;
    `}
`

// Contact

const Address = styled.div`
    margin-bottom: auto;
    flex-order: 0;

    ${media.tabletPortrait`
        margin-top: 34px;
    `}
`
const ContactLink = styled.a`
    cursor: pointer;
`

const Contact = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 18px;
    line-height: 145.6%;
`

// Credit

const MadeBy = styled.a`
    font-size: 14px;
`

const Credit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;

    ${media.tabletPortrait`
        position: absolute;
        bottom: 64px;
        right: 42px;
    `}
    
    ${media.phone`
        position: absolute;
        bottom: 64px;
        right: 85px
    `} 
`

// Modal

const Modal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
`

const Close = styled.div`
    font-size: 14px;
    text-align: right;
    padding: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    color: black;
`

export const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                acf_json
            }
        }
    }
`

export default Footer
