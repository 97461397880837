import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { useMount, useUnmount, useLocation } from 'react-use'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'
import { border } from '../../styles/colors'

const Header = ({theme, overSlider, ...props}) => {
	const [menuActive, setMenuActive] = useState(false)
	const [scrollMenuActive, setScrollMenuActive] = useState(false)
	const [mobileMenuActive, setMobileMenu] = useState(false)
	const location = useLocation();

	useMount(() => {
		window.addEventListener('scroll', handleScroll);	
	})

	useUnmount(() => {
		window.removeEventListener('scroll', handleScroll);
	})

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	}, [scrollMenuActive])

	const handleScroll = () => {
		window.requestAnimationFrame(() => {
			const scrollY = window.scrollY;
		
			if (scrollY > 1000 && !scrollMenuActive) {
				setScrollMenuActive(true)
				setMenuActive(false)
			} 

			if (scrollY < 1000 && scrollMenuActive) {
				setScrollMenuActive(false)
				setMenuActive(false)
			} 
		})
	}
	
	const toggleMenu = (state) => {
		console.log(state)
		setMenuActive(typeof state !== null ? state : !menuActive)
	}

	const renderMenuItems = () => {
		const showActivePage = location && location.pathname !== '/'; 

		const menu = [
			{
				title: 'About',
				link: '/about'
			},	
			{
				title: 'Services',
				link: '/services'
            },
            {
				title: 'Journal',
				link: '/journal'
			},
			{
				title: 'Contact',
				link: '/contact',
			},
		]		

		return menu.map((item, i) => {
			return (
				<MenuItem
					to={item.link}
					onClick={item.onClick}
					activeClassName={'active'}
					partiallyActive={true}
					showActivePage={showActivePage}
				>
					{item.title}
				</MenuItem>
			)
		})	
	}
		
	const renderNavigation = () => {
		return (
			<Navigation>
				{renderMenuItems()}
			</Navigation>
		)
	}

	const renderMenuWrapper = () => {
		return (
			<Wrapper
				{...animatedWrapper}
				animate={menuActive ? 'visible' : 'hidden'}
			>
				<Container>
					<Logo
						to={'/'}
					/>
					{renderNavigation()}
				</Container>
			</Wrapper>
		)
	}

	const renderMenuToggle = (showLogo) => {
		const showActivePage = location && location.pathname !== '/'; 

		return (
			<MenuToggle
				menuActive={menuActive}
			>
				<Container>
					{showLogo && (
						<Logo
							to={'/'}
						/>
					)}

					<MenuItemToggle
						onMouseEnter={() => toggleMenu(true)}	
						onClick={() => toggleMenu(true)}	
						theme={!menuActive && !scrollMenuActive ? theme : 'black'}
						menuActive={menuActive}
					>
						Menu
					</MenuItemToggle>
				</Container>
			</MenuToggle>
		)
	}

	const renderFixedMenu = () => {
		return (
			<FixedMenu
				onMouseLeave={() => toggleMenu(false)}	
			>
				{renderMenuToggle()}
				{renderMenuWrapper()}
			</FixedMenu>
		)
	}

	const renderStaticMenu = () => {
		return (
			<StaticMenu>
				{renderMenuToggle()}
				{renderMenuWrapper()}
			</StaticMenu>
		)
	}

	const renderScrollMenu = () => {
		return (
			<ScrollMenu
				active={scrollMenuActive}
				onMouseLeave={() => toggleMenu(false)}	
			>
				{renderMenuToggle(true)}
				{renderMenuWrapper()}
			</ScrollMenu>
		)
	}

	return (
		<>
			{!overSlider && renderStaticMenu()}
			{overSlider && renderFixedMenu()}
			{renderScrollMenu()}
		</>
	)
}

// Shared

const Logo = styled(Link)`
	background-image: url(${require('../../assets/images/logo-mark-black.svg')});
	${bgIcon}
	width: 37px;
	height: 27px;
	margin-top: 44px;

	${media.phone`
		margin-top: 24px;
		width: 32px;
		height: 23px;
	`}
`

// Layout

const animatedWrapper = {
	initial: 'hidden',
	variants: {
		visible: {
			opacity: 1,
			pointerEvents: 'all',
		},
		hidden: {
			opacity: 0,
			pointerEvents: 'none'
		},
	}
}


const Container = styled.div`
	${container}
	${padding}
	max-width: 1680px;
`

const Wrapper = styled(motion.div)`
	display: flex;
	justify-content: center;
	background: white;
	position: relative;
	z-index: 1;
	padding-bottom: 46px;
	border-bottom: 1px solid ${border};

	${Container} {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	${media.phone`
		padding-bottom: 23px;
	`}
`

// Navigation

const Navigation = styled.div`
	margin-top: 74px;

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	${media.phone`
		margin-top: 51px;
	`}
`

// Menu Item

const menuItem = css`
	font-size: 36px;
	font-weight: 500;	
	line-height: 1em;
	margin-bottom: 2px;

	/* Theme */

	transition: all 0.25s ease;

	${props => {
		if (props.theme == 'white') return css`
			color: white;
		`
	}}

	/* Active / Inactive */

	${props => {
		if (props.showActivePage) return css`
			color: rgba(103, 101, 86, 0.34);

			&:hover {
				color: #132802;	
			}
		`
	}}
	
	&.active {
		color: #132802;
	}

	${media.phone`
		font-size: 24px;
	`}
`

const MenuItem = styled(Link)`
	${menuItem}
`

const MenuItemToggle = styled.div`
	${menuItem}
`

// Menu Toggle

const MenuToggle = styled(motion.div)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 11;
	display: flex;
	justify-content: center;

	${Container} {
		position: relative;

		${MenuItemToggle} {
			position: absolute;
			right: 42px;
			top: 37px;	
			cursor: pointer;
			align-items: flex-end;

			${media.phone`
				top: 24px;	
				right: 22px;
			`}
		}
	}

	${props => {
		if (props.menuActive) return css`
			${MenuItemToggle} {
				color: rgba(103, 101, 86, 0.34);
			}

			pointer-events: none;
		`
	}}
`

// Fixed / Scroll Wrappers

const FixedMenu = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 11;
`	

const ScrollMenu = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	height: 100px;

	background: white;
	margin-top: -16px;
	border-bottom: 1px solid ${border};

	/* Animation */

	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s ease;

	${props => {
		if (props.active) return css`
			opacity: 1;
			pointer-events: all;
		`
	}}

	${media.phone`
		height: 75px;
		margin-top: 0;
	`}
`	

const StaticMenu = styled.div`
	${Wrapper} {
		opacity: 1 !important;
		pointer-events: all !important;
		border-bottom: 0;
		background: none;
	}

	${MenuItemToggle} {
		display: none;
	}

	${Navigation} {
		margin-top: 37px;

		${media.phone`
			margin-top: 24px;
				
			/* ${MenuItem}:not(.active) {
				display: none;
			} */
		`}
	}

	${media.phone`
		margin-bottom: 53px;
	`}
`	

export default Header
