import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { forEach } from 'lodash'
import moment from 'moment'
import { AnimatePresence, motion } from 'framer-motion';
import prefetchImages from 'prefetch-image';
import useSmoothScroll from 'use-smooth-scroll'

import { Layout, CallToAction, Block, InstagramFeed, ParallaxImage, JournalFeed } from '../components'

import { container, padding, bgImage, linkText, wrapper, headingText, bgIcon, heading, absoluteCover, margins } from '../styles/global'
import { blockPadding } from '../components/Block/Block';
import { border, olive } from '../styles/colors';
import { parseACF, scrollToRef } from '../utils';
import { useMount } from 'react-use';
import { media, useBreakpoint } from '../styles/utils';

const Index = (props) => {
    let data = parseACF(useStaticQuery(query), 'allWordpressInfopages');
    
    if (props.previewData) {
        data = JSON.parse(props.previewData.acf_json) 
    }

    const [activeArticle, setActiveArticle] = useState(data.journal && data.journal[0])
    const introduction = React.useRef()
    const isPhone = useBreakpoint('phone');
    const isTabletPortrait = useBreakpoint('tabletPortrait');

    useMount(() => {
        prefetchArticleImages()
    })

    const getServiceSlides = () => {
        let slides = []

        forEach(data.services_slider, (item) => {
            slides.push({
                caption: item.title,
                caption_link: `/services/${item.slug}`,
                use_caption: item.title ? true : false,
                image: item.image,
            })
        })

        return slides
    }

    const prefetchArticleImages = () => {
        const articles = data.journal;
            
        const images = [];
    
        forEach(articles, (article) => {
            if (!article.hero_image) return;
            images.push(article.hero_image.sizes.medium2)
        })
        
        prefetchImages(images)
    }

    const renderHero = () => {
        return (
            <Hero>
                <HeroContent>
                    <Container>


                        {/* <Info>
                            <Logo/>
                            <Description>
                                Horticulture
                            </Description>
                        </Info> */}


                        <LogoCentred />


                        
                        <Arrow
                            onClick={() => scrollToRef(introduction)}
                            {...AnimatedArrow}
                        />
                        
                        {/* <LogoMark/> */}
                    </Container>
                </HeroContent>

                <Image
                    key={data.hero_image.id}
                    src={data.hero_image.sizes.full_width}
                >
                    {(src, loading) => {
                        return (
                            <BGImage
                                key={data.hero_image.id}
                                image={src} 
                                visible={!loading}
                            />
                        )
                    }}
                </Image> 
            </Hero>
        )
    }

    const renderIntro = () => {
        return (
            <Intro
                ref={introduction}
            >
                <HeadingText>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.hero_statement}}
                    />

                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{__html: data.hero_description}}  
                        />
                        
                        <InternalLink
                            to={'/services'}
                        >
                            View our services
                        </InternalLink>
                    </Info>
                </HeadingText>
            </Intro>
        )
    }


    const renderAbout = () => {

        const videoData = {
            video: data.about_video,
            use_caption: data.about_video_use_caption,
            caption: data.about_video_caption
        }

        return (
            <About>


                {data.about_use_video && (     
                    <Block
                        layout={'video'}
                        {...videoData}
                    />
                )}

                <HeadingText>
                    <Heading>{data.about_heading}</Heading>

                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{__html: data.about_description}}  
                        />
                        
                        { data.about_use_read_more_link && data.about_read_more_link && (
                            <InternalLink
                                to={data.about_read_more_link.url}
                            >
                                {data.about_read_more_link_text}
                            </InternalLink>
                        )}
                        
                    </Info>
                </HeadingText>

            </About>
        )
    }

    const renderServicesSlider = () => {
        return (
            <Services>
                <Block
                    layout={'image_slider'}
                    slides={getServiceSlides()}
                    controls
                />
            </Services>
        )
    }

    const renderInstagram = () => {
        if (!data.display_instagram_section) return false

        return (
            <Instagram>
                <HeadingText>
                    <Heading>{data.instagram_title}</Heading>
                    <Info>
                        <Description
                            dangerouslySetInnerHTML={{__html: data.instagram_description}}  
                        />
                        
                        <InstagramLink
                            href={`https://www.instagram.com/${data.instagram_username}`}
                            target={`_blank`}
                        >   
                            <Icon/>
                            <Description>
                                View our profile
                            </Description>
                        </InstagramLink>
                    </Info>
                </HeadingText>
            </Instagram>
        )
    }

    const renderArticles = () => {
        if (!data.journal) return;

        return data.journal.map((item, i) => {
            return (
                <Article
                    key={i}
                    to={`/journal/${item.slug}`}
                    active={item.slug == activeArticle.slug}
                    onMouseEnter={() => setActiveArticle(item)}
                    onMouseLeave={() => setActiveArticle(data.journal[0])}
                >
                    <Arrow/>
                    <Heading>{item.title}</Heading>
                    <Subheading>{moment.unix(item.publishedDateUnix).format('MM.DD.YYYY')}</Subheading>
                </Article>
            )
        })
    }

    const renderJournal = () => {
        if (!data.display_journal_section) return false

        return (
            <Journal>
                <ActiveJournal>
                    <AnimatePresence
                        exitBeforeEnter={false}
                    >
                        {activeArticle && (
                            <JournalImage
                                key={activeArticle.slug}
                                image={activeArticle.hero_image && activeArticle.hero_image.sizes.medium2}
                                {...animatedJournalImage}
                            />  
                        )}
                    </AnimatePresence>
                </ActiveJournal>

                <Info>
                    <Heading>{data.journal_heading}</Heading>
                    <Description
                        dangerouslySetInnerHTML={{__html: data.journal_description}}
                    />

                    {!isTabletPortrait && (
                        <>
                            <Listing>
                                {renderArticles()}
                            </Listing>

                            <InternalLink
                                to={'/journal'}
                            >
                                View All
                            </InternalLink>
                        </>
                    )}
                </Info>
            </Journal>
        )
    }

	return (
		<Layout
            meta={data && data.seo}
            headerTheme={'white'}
            headerOverSlider={true}
        >
            <Wrapper>
                {renderHero()}

                <Container>
                    {renderIntro()}
                    {renderAbout()}
                    {renderServicesSlider()}  
                    {renderInstagram()}
                </Container>

                <InstagramFeed
                    slides={data.instagram}
                />

                <CallToAction/>

                <Container>
                    {renderJournal()}
                </Container>

                {isTabletPortrait && (
                    <JournalFeed
                        slides={data.journal}
                    />
                )}
            </Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const InternalLink = styled(Link)`
    ${linkText}
`

const HeadingText = styled.div`
    ${headingText}
`

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const Arrow = styled(motion.div)`
    ${bgIcon}
`

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

const Icon = styled.div`
    ${bgIcon};
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
    padding-bottom: 160px;

    ${media.phone`
        padding-bottom: 100px;
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
    flex-direction: column;
`

// Hero

const LogoCentred = styled.div``
const LogoMark = styled.div``
const Logo = styled.div``

const Hero = styled.div`
    width: 100vw;
    min-height: 100vh;
    position: relative;
    background-color: #C4C4C4;
    overflow: hidden;
    
    ${BGImage} {
        ${absoluteCover}
        height: 100vh;
        z-index: 1;
        mix-blend-mode: multiply;
    }
`

const AnimatedArrow = {
    animate: {
        translateX: ['-50%', '-50%', '-50%'],
        translateY: [0, -40, 0],
    },
    transition: { 
        delay: 5,
        ease: [0.645, 0.045, 0.255, 1],
        duration: 0.65,
        flip: Infinity,
        repeatDelay: 5,
    }
}

const HeroContent = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;

    ${Container} {
        position: relative;
        z-index: 2;
        justify-content: center;
        align-items: center; 

        ${LogoCentred} {    
            background-image: url(${require('../assets/images/botanic-full-logo.png')});
            ${bgImage}
            width: 500px;
            height: 202px;
            margin-top: -40px;

            ${media.tabletPortrait`
                width: 302px;
                height: 122px;
            `}
        }


        /* ${Info} {
            display: flex;
            align-items: center;
            margin-top: 24px;
            
            ${Logo} {
                background-image: url(${require('../assets/images/logo.svg')});
                ${bgImage}
                width: 198px;
                height: 40px;
                transform: translateY(-3px);
            }

            ${Description} {
                font-size: 46px;
                color: white;
                margin-left: 191px;
            }

            ${media.tabletPortrait`
                flex-direction: column;
                align-items: flex-start;

                ${Description} {
                    margin-left: 0;
                    font-size: 24px;
                }

                ${Logo} {
                    width: 126px;
                    height: 25px;
                    margin-top: 20px;
                    transform: none;
                }
            `}

            ${media.phone`
                margin-top: 0;
            `}
        } */
        
        ${Arrow} {
            background-image: url(${require('../assets/icons/home-hero-arrow.svg')});
            width: 30px;
            height: 40px;
            position: absolute;
            bottom: 45px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;

            ${media.phone`
                bottom: 24px;
            `}
        }

        ${LogoMark} {
            background-image: url(${require('../assets/images/logo-mark-white.svg')});
            width: 48px;
            height: 36px;
            position: absolute;
            bottom: 45px;

            ${media.phone`
                display: none;
            `}
        }
    }
`

// Intro

const Intro = styled.div`
    padding-top: 57px;

    ${media.phone`
        padding-top: 50px;
    `}

    ${Heading} {
        max-width: 300px;
    }
`

// Services

const Services = styled.div`
    margin-top: 40px;

    ${media.phone`
        margin-top: 20px;
    `}
`

// About

const About = styled.div`
    margin-top: 40px;

    ${media.phone`
        margin-top: 20px;
    `}
`

// Instagram

const InstagramLink = styled.a`
    display: flex;
    cursor: pointer;

    ${Icon} {
        background-image: url(${require('../assets/icons/instagram.svg')});
        height: 25px;
        width: 25px;
        margin-right: 14px;
    }

    ${Description} {
        ${linkText}
    }
`

const Instagram = styled.div`
    margin-top: 120px;

    ${media.phone`
        margin-top: 32px;

        ${HeadingText} {
            margin-bottom: 40px;
        }
    `}
`

// Journal

const ActiveJournal = styled.div`
    background: #F1F1F1;
    height: 875px;
    position: relative;
`

const animatedJournalImage = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.25,
		}
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.25,
		}
	},
}


const JournalImage = styled(motion.div)`
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image});  
    ${bgImage};
    ${absoluteCover};
`

const Journal = styled.div`
    margin-top: 180px;
    display: flex;
    justify-content: space-between;

    ${Subheading}{
        margin-right: 0!important;
    }

    ${ActiveJournal},
    ${Info} {
        flex: 0 1 50%;
        flex-basis: calc(50% - 22.5px);
        display: flex;
        flex-direction: column;

        > ${Heading} {
            ${heading}
        }

        ${Description} {
            margin-top: 30px;
            max-width: 500px;
        }

        ${InternalLink} {
            margin-top: 40px;
        }
    }

    ${media.tabletPortrait`
        flex-direction: column;
        margin-top: 87px;
        margin-bottom: 60px;

        ${ActiveJournal} {
            display: none;
        }
    `}
`

// Article Listing

const Listing = styled.div`
    margin-top: 40px;
`

const Article = styled(Link)`
    border-bottom: 1px solid ${border};
    padding: 25px 0;
    color: ${olive};
    cursor: pointer;
    display: flex;

    ${Heading} {
        max-width: calc(100% - 64px - 16px - 200px);
    }

    ${Subheading} {
        margin-left: auto;
        min-width: 200px;
        text-align: right;
    }

    ${Arrow} {
        background-image: url(${require('../assets/icons/article-arrow.svg')});
        background-position: left center;
        width: 0;
        height: 29px;
        transition: all 0.25s ease;
        will-change: width;
        opacity: 0;
        transform: translateY(2px);
        align-self: center;
    }

    ${props => {
        if (props.active) return css`
            ${Arrow} {
                opacity: 1;
                width: 64px; 
                margin-right: 16px;
            }
        `
    }}
`


export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`


export default Index