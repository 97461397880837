import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from "react-progressive-image"
import Swiper from 'react-id-swiper'

import { media, useBreakpoint } from '../../styles/utils'
import { container, bgIcon, padding, hoverState, bgImage, maxWidth } from '../../styles/global'
import { useMount } from 'react-use';
import { Link } from 'gatsby';

const InstagramFeed = (props) => {
	const {slides, sliderStyles} = props;
	const [swiper, updateSwiper] = useState(null);
	const canLoop = slides && slides.length > 1;
	const isPhone = useBreakpoint('phone');

	const swiperParams = {
		getSwiper: updateSwiper,
		// loop: canLoop,
		slidesPerView: 'auto',
		spaceBetween: isPhone ? 25 : 30,
		freeMode: true,
	}
	
	const renderSlides = () => {
		if (!slides) return;
		
		return slides.filter(function(item) {
			if (item.media_type == 'VIDEO')  return false // skip video
			return true;
		  }).map((item, i) => {
			if (!item.media_url) return
			
			return (
				<Slide
					key={i}
				>
					<Image 
						className={'slider-image'}
						image={item.media_url} 
					/>
				</Slide>
			)
		})
	}

	return (
		<Wrapper
			sliderStyles={sliderStyles}
		>
			<Swiper
				{...swiperParams}
			>
				{renderSlides()}
			</Swiper>
		</Wrapper>
	)
}

// Shared


const Image = styled.div`
	background-image: url(${props => props.image});  
	${bgImage}
`

// Layout

const defaultSliderStyles = css`
	height: 352px;
	max-width: 100vw;

	${media.phone`
		height: 273px;
		padding-left: 44px;
	`}
`

const Wrapper = styled.div`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles};
		cursor: grab;
		margin-bottom: 176px;

		${media.phone`
			margin-bottom: 75px;
		`}
	}
`

// Slide

const Slide = styled.div`
	width: 352px !important;

	${Image} {
		height: 100%;
	}

	${media.phone`
		width: 273px !important;
	`}
`

export default InstagramFeed
